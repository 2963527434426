@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}

address {
  font-style: normal;
}

p a {
  font-weight: 700;
}

p a:hover {
  text-decoration: underline;
}

.close-btn:hover {
  .close-btn__icon {
    display: none;
  }
  .close-btn__icon--hover {
    display: block;
  }
}

.close-btn__icon--hover {
  display: none;
}

.intro-words {
  font-size: 2.4rem;
  font-weight: 700;
}

.yt-lite {
  background: transparent!important;
  background-repeat: no-repeat;
  cursor: pointer;
  padding-bottom: 56.25%;
}

.lyt-activated {
  background-image: none!important;
  display: inline-block;
  padding-bottom: inherit;
}

div:has(.lyt-activated) + .placeholder {
  display: none;
}

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}
